import React, { useEffect} from 'react';
import moment from 'moment';
import 'daterangepicker/daterangepicker.css';
import 'daterangepicker';
import $ from 'jquery';
import Style from './filters.module.css'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const DateFilter = ({startDate,endDate,setStartDate,setEndDate}:{startDate:any;endDate:any;setStartDate:any;setEndDate:any}) => {
    
    useEffect(() => {
        const cb = (start: moment.Moment, end: moment.Moment) => {
            setStartDate(start);
            setEndDate(end);
            document.querySelector('#reportrange span')!.innerHTML = startDate.utc().format('MMMM D, YYYY') + ' - ' + endDate.utc().format('MMMM D, YYYY');
        };

        $('#reportrange').daterangepicker({
            startDate,
            endDate,
            minDate: '26/04/2024',
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        }, cb);

        cb(startDate, endDate);
    }, [startDate , endDate , setStartDate , setEndDate]); 
    
    return (
        <div className={Style.datepicker}>
        <div id="reportrange" className={Style.reportrange} style={{ background: '#fff', cursor: 'pointer', padding: '7.29px 10px', border: '1px solid #ccc', width: '100%' }}>
            <CalendarMonthIcon/>&nbsp;
            <span></span><KeyboardArrowDownIcon />
        </div>
        </div>
    );
};

export default DateFilter;
