import React from 'react'
import ClientBoard from './Client/ClientBoard'


function Boards({store , setStoreClient}:{store:any; setStoreClient:(data:any)=>void;}) {

  return (
    <div>
      <ClientBoard store={store} setStoreClient={setStoreClient}/>
    </div>
  )
}

export default Boards