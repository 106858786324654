import React, { useEffect } from "react";
import Style from "./client.module.css";
import moment from "moment";
import ClientHeader from "./ClientHeader";
import ClientCard from "../../../Card/ClientCard";
import GraphCard from "../../../Card/GraphCard";
import SimpleCard from "../../../Card/SimpleCard";
import DateFilter from "../../../Filters/DateFilter";
import ProductFilter from "../../../Filters/ProductFilter";
import ExperimentFilter from "../../../Filters/ExperimentFilter";
import { Button } from "@mui/material";
import axios from "axios";
import Loader from "../../../Loader/Loader";
import ErrorCard from "../../../Card/ErrorCard";

function ClientBoard({store , setStoreClient}:{store:any; setStoreClient:(data:any)=>void;}) {
  const [startDate, setStartDate] = React.useState<moment.Moment>(
    moment().subtract(7, "days")
  );
  const [endDate, setEndDate] = React.useState<moment.Moment>(
    moment().subtract(1, "days")
  );

  const [selectedProduct, setSelectedProduct] = React.useState<any>([]);
  const [storeDetails, setStoreDetails] = React.useState<any>();
  const [selectedExperiment, setSelectedExperiment] = React.useState<any>(null);
  const [productsList, setProductsList] = React.useState<any>();
  const [populateProducts, setPopulateProducts] = React.useState<any>();
  const [experiments, setExperiments] = React.useState<any>();
  const [withTroopodData, setWithTroopodData] = React.useState<any>(null);
  const [withoutTroopodData, setWithoutTroopodData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  /** It will execute for the first time on page load for setting default store , default product and experiment **/

  const formatDateStart = (date: moment.Moment): any => {
    console.log(date.format("YYYY-MM-DD 18:30:00"));
    return date.utc().format("YYYY-MM-DD 18:30:00");
  };

  const formatDateEnd = (date: moment.Moment): any => {
    console.log(date.format("YYYY-MM-DD HH:mm:ss"));
    return date.utc().format("YYYY-MM-DD 18:29:59");
  };

  useEffect(() => {
    const hostName = process.env.REACT_APP_ENV === "production" ? "api.prod" : "api.staging";
    const fetchStores = async () => {
      try {
        let accessToken: any = localStorage.getItem("accesstoken");
        let refreshToken: any = localStorage.getItem("refreshtoken");

        if (!accessToken) {
          console.error("Access token not found");
          return;
        }

        accessToken = JSON.parse(accessToken);
        refreshToken = JSON.parse(refreshToken);

        const isTokenExpired = (accessToken: any) => {
          if (!accessToken) {
            return true;
          }

          const tokenData = JSON.parse(atob(accessToken.split(".")[1]));
          const expirationTime = tokenData.exp * 1000;
          const currentTime = Date.now();

          return currentTime >= expirationTime;
        };

        const isTokenExpire: any = isTokenExpired(accessToken);
        if (isTokenExpire && refreshToken) {
          const refreshResponse = await axios.post(
            `https://${hostName}.troopod.io/api/token/refresh/`,
            { refresh_token: refreshToken }
          );

          localStorage.setItem(
            "accesstoken",
            JSON.stringify(refreshResponse.data.access)
          );

          const storeResponse = await axios.get(
            `https://${hostName}.troopod.io/store/`,
            {
              headers: {
                Authorization: `Bearer ${refreshResponse.data.access}`,
              },
            }
          );
          if(!store){
          setStoreClient(storeResponse.data[0]);
          }
          setStoreDetails(storeResponse.data);
          return storeResponse.data[0];
        } else {
          const response = await axios.get(
            `https://${hostName}.troopod.io/store/`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          if(!store)
          {
            setStoreClient(response.data[0]);
          }
          setStoreDetails(response.data);
          return response.data[0];
        }
      } catch (err: any) {
        console.error("Failed to fetch stores:", err);
      }
    };

    const fetchExperimentData = async ({ store }: { store: any }) => {
      const hostName = process.env.REACT_APP_ENV === "production" ? "api.prod" : "api.staging";
      try {
        const response = await fetch(
          `https://${hostName}.troopod.io/experiment/filter?store=${store?.id}&holdout_experiment=True`
        );
        const data = await response.json();
        setSelectedExperiment(data[0]);
        setExperiments(data);
        return data;
      } catch (error) {
        console.error("Error fetching experiment data:", error);
      }
    };

    const fetchProductData = async ({ store }: { store: any }) => {
      try {
        const response = await fetch(
          `https://${hostName}.troopod.io/product/store/${store?.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
            },
          }
        );
        const data = await response.json();
        if (data.length > 0) {
          setSelectedProduct([data[0]]);
          return data[0];
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    const fetchData = async () => {
      let store = await fetchStores();
      let experiments = await fetchExperimentData({ store });
      let products = await fetchProductData({ store });
      if (store && products && experiments && startDate && endDate) {
        setLoading(true);
        fetch(
          `https://${hostName}.troopod.io/analytics/api/dashboard?store_id=${
            store.id
          }&product_ids=[${products.client_id}]&experiment_id=${
            experiments.id
          }&start_date=${formatDateStart(startDate)}&end_date=${formatDateEnd(
            endDate
          )}`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data)
            setWithTroopodData(data.experiment_data.withTroopod);
            setWithoutTroopodData(data.experiment_data.withoutTroopod);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error fetching data:", error);
          });
      } else {
        alert("Missing Parameters");
      }
    };

    fetchData();
  }, []);

  /**It will execute on store change**/

  useEffect(() => {
    const hostName = process.env.REACT_APP_ENV === "production" ? "api.prod" : "api.staging";    
    if (storeDetails?.length > 1) {
      const fetchExperimentData = async ({ store }: { store: any }) => {
        try {
          const response = await fetch(
            `https://${hostName}.troopod.io/experiment/filter?store=${store?.id}&holdout_experiment=True`
          );
          const data = await response.json();
          setSelectedExperiment(data[0]);
          setExperiments(data);
          return data;
        } catch (error) {
          console.error("Error fetching experiment data:", error);
        }
      };

      const fetchProductData = async ({ store }: { store: any }) => {
        const hostName = process.env.REACT_APP_ENV === "production" ? "api.prod" : "api.staging";
        try {
          const response = await fetch(
            `https://${hostName}.troopod.io/product/store/${store?.id}`
            , {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
              },
            }
          );
          const data = await response.json();
          if (data.length > 0) {
            setSelectedProduct([data[0]]);
            return data[0];
          }
        } catch (error) {
          console.error("Error fetching product data:", error);
        }
      };

      const fetchData = async () => {
        const hostName = process.env.REACT_APP_ENV === "production" ? "api.prod" : "api.staging";
        let experiments = await fetchExperimentData({ store });
        let products = await fetchProductData({ store });
        if (store && products && experiments && startDate && endDate) {
          setLoading(true);
          fetch(
            `https://${hostName}.troopod.io/analytics/api/dashboard?store_id=${
              store.id
            }&product_ids=[${products.client_id}]&experiment_id=${
              experiments.id
            }&start_date=${formatDateStart(startDate)}&end_date=${formatDateEnd(
              endDate
            )}`
          )
            .then((response) => response.json())
            .then((data) => {
              setWithTroopodData(data.experiment_data.withTroopod);
              setWithoutTroopodData(data.experiment_data.withoutTroopod);
              setLoading(false);
            })
            .catch((error) => {
              setLoading(false);
              setWithTroopodData("");
              setWithoutTroopodData("");
              console.error("Error fetching data:", error);
            });
        } else {
          alert("Missing Parameters");
        }
      };

      fetchData();
    }
  }, [store, storeDetails?.length]);

  /**It will execute on store change and also enter product title or product id**/

  useEffect(() => {
    const hostName = process.env.REACT_APP_ENV === "production" ? "api.prod" : "api.staging";
    fetch(
      `https://${hostName}.troopod.io/product/store/${store?.id}?query=${
        populateProducts ? populateProducts : ""
      }`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setProductsList([{
          "id": 3318,
          "client_id": "Select All Products",
          "title": "",
          "product_status": "active",
          "product_live_status": "ProductLiveStatus.INACTIVE",
          "created_at": "2024-04-05T08:57:48.203423Z",
          "updated_at": "2024-07-18T16:49:28.124670Z"
      }, ...data]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [populateProducts, store]);

  /**It will execute on store change**/

  useEffect(() => {
    const hostName = process.env.REACT_APP_ENV === "production" ? "api.prod" : "api.staging";
    fetch(
      `https://${hostName}.troopod.io/experiment/filter?store=${store?.id}&holdout_experiment=True`
    )
      .then((response) => response.json())
      .then((data) => {
        setExperiments(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [store]);

  /**This will execute when user click on filter button then data will populate on filter params**/

  const handleClientFilter = () => {
    const hostName = process.env.REACT_APP_ENV === "production" ? "api.prod" : "api.staging";
    if (
      store &&
      selectedProduct &&
      selectedExperiment &&
      startDate &&
      endDate
    ) {
      let productIds: any = '';
      let preset = '';
      if (selectedProduct && selectedProduct.some((product: any) => product.client_id !== 'Select All Products')) {
        productIds = selectedProduct.map((product: any) => `"${product.client_id}"`).join(', ');
      } else if(selectedProduct.some((product: any) => product.client_id == 'Select All Products')){
        preset = '&preset=all';
      }
      
      setLoading(true);

      fetch(
        `https://${hostName}.troopod.io/analytics/api/dashboard?store_id=${
          store.id
        }&product_ids=[${productIds}]${preset}&experiment_id=${
          selectedExperiment.id
        }&start_date=${formatDateStart(startDate)}&end_date=${formatDateEnd(
          endDate
        )}`
      )
        .then((response) => response.json())
        .then((data) => {
          setWithTroopodData(data.experiment_data.withTroopod);
          setWithoutTroopodData(data.experiment_data.withoutTroopod);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setWithTroopodData("");
          setWithoutTroopodData("");
          console.error("Error fetching data:", error);
        });
    } else {
      alert("Missing Parameters");
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <ClientHeader
        storeDetails={storeDetails}
        store={store}
        setStore={setStoreClient}
      />
      <div className={Style.filtersection}>
        <div className={Style.filter}>
          <DateFilter
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </div>
        <div className={Style.filter}>
          <ProductFilter
            setSelectedProduct={setSelectedProduct}
            selectedProduct={selectedProduct}
            products={productsList}
            setPopulateProducts={setPopulateProducts}
          />
        </div>
        <div className={Style.filter}>
          <ExperimentFilter
            setSelectedExperiment={setSelectedExperiment}
            selectedExperiment={selectedExperiment}
            experiments={experiments}
          />
        </div>
        <div className={Style.filter}>
          <Button variant="contained" onClick={handleClientFilter}>
            Filter
          </Button>
        </div>
        <div style={{width:'300px', display:'grid'}}>
        <p style={{fontSize:'12px'}}>Note: Only refer the data when the Page Views of a particular SKU of WithoutTroopod Variant is at least 1000 Page Views and above or, is similar to Page Views in WithTroopod Variant.</p>
         </div>
      </div>
      <div id="clientcontent">
        <div>
        <Overallperformance
          withTroopodData={withTroopodData}
          withoutTroopodData={withoutTroopodData}
        />
        <div className={Style.troopodABsection}>
          <h1>Troopod A/B test Performance</h1>
          <div className={Style.upsidesection}>
            <GraphCard
              title="Conversion Rate Upside"
              color="yellow"
              count={
                withTroopodData?.purchases !== undefined &&
                withoutTroopodData?.purchases !== undefined
                  ? (
                      ((withTroopodData?.purchases / withTroopodData?.views -
                        withoutTroopodData?.purchases /
                          withoutTroopodData?.views) /
                        (withoutTroopodData?.purchases /
                          withoutTroopodData?.views)) *
                      100
                    ).toFixed(2) + "%"
                  : "N/A"
              }
            />
            <GraphCard
              title="Add to Cart Rate Upside"
              color="blue"
              count={
                withTroopodData?.cta_clicks !== undefined &&
                withoutTroopodData?.cta_clicks !== undefined &&
                withoutTroopodData?.views !== undefined
                  ? (
                      ((withTroopodData?.cta_clicks / withTroopodData?.views -
                        withoutTroopodData?.cta_clicks /
                          withoutTroopodData?.views) /
                        (withoutTroopodData?.cta_clicks /
                          withoutTroopodData?.views)) *
                      100
                    ).toFixed(2) + "%"
                  : "N/A"
              }
            />

            <GraphCard
              title="Buy Now Rate Upside"
              color="yellow"
              count={
                withTroopodData?.buy_now_click !== undefined &&
                withoutTroopodData?.buy_now_click !== undefined &&
                withoutTroopodData?.views !== undefined
                  ? (
                      ((withTroopodData?.buy_now_click /
                        withTroopodData?.views -
                        withoutTroopodData?.buy_now_click /
                          withoutTroopodData?.views) /
                        (withoutTroopodData?.buy_now_click /
                          withoutTroopodData?.views)) *
                      100
                    ).toFixed(2) + "%"
                  : "N/A"
              }
            />

            <GraphCard
              title="CTA to Purchase Rate Upside"
              color="blue"
              count={
                withTroopodData?.clicks !== undefined &&
                withoutTroopodData?.clicks !== undefined &&
                withoutTroopodData?.purchases !== undefined
                  ? (
                      ((withTroopodData?.purchases / withTroopodData?.clicks -
                        withoutTroopodData?.purchases /
                          withoutTroopodData?.clicks) /
                        (withoutTroopodData?.purchases /
                          withoutTroopodData?.clicks)) *
                      100
                    ).toFixed(2) + "%"
                  : "N/A"
              }
            />
          </div>
          <div className={Style.comparesection}>
            <WithTroopod withTroopodData={withTroopodData} />
            <WithoutTroopod withoutTroopodData={withoutTroopodData} />
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default ClientBoard;

/* over all performance section */

const Overallperformance = ({
  withTroopodData,
  withoutTroopodData,
}: {
  withTroopodData: any;
  withoutTroopodData: any;
}) => {
  return (
    <div>
      <div className={Style.overallperformancesection}>
        <h1>Overall Page Performance</h1>
        <div className={Style.overallsection}>
          <ClientCard
            icon="Leaderboard"
            title="Page Views"
            count={
              (withTroopodData?.views ?? 0) + (withoutTroopodData?.views ?? 0)
            }

          />
          <ClientCard
            icon="Leaderboard"
            title="Add to Cart"
            count={
              (withTroopodData?.cta_clicks ?? 0) +
              (withoutTroopodData?.cta_clicks ?? 0)
            }

          />
          <ClientCard
            icon="Leaderboard"
            title="Buy Now"
            count={
              (withTroopodData?.buy_now_click ?? 0) +
              (withoutTroopodData?.buy_now_click ?? 0)
            }
          />
          <ClientCard
            icon="Leaderboard"
            title="Orders"
            count={
              (withTroopodData?.purchases ?? 0) +
              (withoutTroopodData?.purchases ?? 0)
            }
          />
          <ClientCard
            icon="Leaderboard"
            title="Conversion Rate"
            count={
              withoutTroopodData?.views !== undefined &&
              withTroopodData?.views !== undefined 
                ? (
                    ((withTroopodData?.purchases + withoutTroopodData?.purchases) /
                      (withTroopodData?.views  + withoutTroopodData?.views )) *
                    100
                  ).toFixed(2) + "%"
                : "N/A"
            }
            percentage={{
              color: "success",
              amount: `${
                withoutTroopodData?.views !== undefined 
                  ? (
                      (withoutTroopodData?.purchases / withoutTroopodData?.views) * 100
                    ).toFixed(2) + "%"
                  : "N/A"
              }`,
              label: "Without Troopod",
            }}
          />
          <ClientCard
            icon="Leaderboard"
            title="Add to Cart Rate"
            count={
               withoutTroopodData?.views !== undefined&& 
               withTroopodData?.views !== undefined 
                ? (
                    ((withTroopodData?.cta_clicks + withoutTroopodData?.cta_clicks) /
                      (withTroopodData?.views + withoutTroopodData?.views)) *
                    100
                  ).toFixed(2) + "%"
                : "N/A"
            }
            percentage={{
              color: "success",
              amount: `${
                withoutTroopodData?.views !== undefined 
                  ? (
                      (withoutTroopodData?.cta_clicks / withoutTroopodData?.views) * 100
                    ).toFixed(2) + "%"
                  : "N/A"
              }`,
              label: "without Troopod",
            }}
          />
          <ClientCard
            icon="Leaderboard"
            title="Buy Now Rate"
            count={
              withoutTroopodData?.views !== undefined &&
              withTroopodData?.views !== undefined 
                ? (
                    ((withTroopodData?.buy_now_click + withoutTroopodData?.buy_now_click) /
                      (withTroopodData?.views + withoutTroopodData?.views )) *
                    100
                  ).toFixed(2) + "%"
                : "N/A"
            }
            percentage={{
              color: "success",
              amount: `${
                withoutTroopodData?.views !== undefined &&
                withTroopodData?.views !== undefined 
                  ? (
                      (withoutTroopodData?.buy_now_click / withoutTroopodData?.views) * 100
                    ).toFixed(2) + "%"
                  : "N/A"
              }`,
              label: "without Troopod",
            }}
          />
          <ClientCard
            icon="Leaderboard"
            title="CTA to Purchase Rate"
            count={
              withoutTroopodData?.views !== undefined &&
              withTroopodData?.views !== undefined 
                ? (
                    ((withTroopodData?.purchases + withoutTroopodData?.purchases) /
                      (withTroopodData?.clicks + withoutTroopodData?.clicks)) *
                    100
                  ).toFixed(2) + "%"
                : "N/A"
            }
            percentage={{
              color: "success",
              amount: `${
                withoutTroopodData?.clicks !== undefined 
                  ? (
                      (withoutTroopodData?.purchases / withoutTroopodData?.clicks) * 100
                    ).toFixed(2) + "%"
                  : "N/A"
              }`,
              label: "Without Troopod",
            }}
          />
        </div>
      </div>
    </div>
  );
};

/**with Troopod component **/

const WithTroopod = ({ withTroopodData }: { withTroopodData: any }) => {
  return (
    <div className={Style.withTroopod}>
      <h4>With Troopod</h4>
      <SimpleCard title="Page Views" count={withTroopodData?.views ?? "N/A"} />
      <SimpleCard
        title="Add to Cart"
        count={withTroopodData?.cta_clicks ?? "N/A"}
      />
      <SimpleCard
        title="Buy Now"
        count={withTroopodData?.buy_now_click ?? "N/A"}
      />
      <SimpleCard title="Orders" count={withTroopodData?.purchases ?? "N/A"} />
      <SimpleCard
        title="Conversion Rate"
        count={
          withTroopodData?.views !== undefined 
            ? (
                (withTroopodData?.purchases / withTroopodData?.views) *
                100
              ).toFixed(2) + "%"
            : "N/A"
        }
      />
      <SimpleCard
        title="Add to Cart Rate"
        count={
          withTroopodData?.views !== undefined 
            ? (
                (withTroopodData?.cta_clicks / withTroopodData?.views) *
                100
              ).toFixed(2) + "%"
            : "N/A"
        }
      />
      <SimpleCard
        title="Buy Now Rate"
        count={
          withTroopodData?.views !== undefined 
            ? (
                (withTroopodData?.buy_now_click / withTroopodData?.views) *
                100
              ).toFixed(2) + "%"
            : "N/A"
        }
      />
      <SimpleCard
        title="CTA to Purchase"
        count={
          withTroopodData?.clicks !== undefined
            ? (
                (withTroopodData?.purchases / withTroopodData?.clicks) *
                100
              ).toFixed(2) + "%"
            : "N/A"
        }
      />
    </div>
  );
};

/**without Troopod component**/

const WithoutTroopod = ({
  withoutTroopodData,
}: {
  withoutTroopodData: any;
}) => {
  return (
    <div className={Style.withoutTroopod}>
      <h4>Without Troopod</h4>
      <SimpleCard
        title="Page Views"
        count={withoutTroopodData?.views ?? "N/A"}
      />
      <SimpleCard
        title="Add to Cart"
        count={withoutTroopodData?.cta_clicks ?? "N/A"}
      />
      <SimpleCard
        title="Buy Now"
        count={withoutTroopodData?.buy_now_click ?? "N/A"}
      />
      <SimpleCard
        title="Orders"
        count={withoutTroopodData?.purchases ?? "N/A"}
      />
      <SimpleCard
        title="Conversion Rate"
        count={
          withoutTroopodData?.views !== undefined 
            ? (
                (withoutTroopodData?.purchases / withoutTroopodData?.views) *
                100
              ).toFixed(2) + "%"
            : "N/A"
        }
      />
      <SimpleCard
        title="Add to Cart Rate"
        count={
          withoutTroopodData?.views !== undefined 
            ? (
                (withoutTroopodData?.cta_clicks / withoutTroopodData?.views) *
                100
              ).toFixed(2) + "%"
            : "N/A"
        }
      />
      <SimpleCard
        title="Buy Now Rate"
        count={
          withoutTroopodData?.views !== undefined 
            ? (
                (withoutTroopodData?.buy_now_click /
                  withoutTroopodData?.views) *
                100
              ).toFixed(2) + "%"
            : "N/A"
        }
      />
      <SimpleCard
        title="CTA to Purchase"
        count={
          withoutTroopodData?.clicks !== undefined 
            ? (
                (withoutTroopodData?.purchases / withoutTroopodData?.clicks) *
                100
              ).toFixed(2) + "%"
            : "N/A"
        }
      />
    </div>
  );
};
