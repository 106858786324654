import * as React from 'react';
import Styles from './loader.module.css'

export default function Loader() {
  return (
    <div className={Styles.loadercontainer}>
        <svg className={Styles.spinner} width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className={Styles.path} fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
       </svg>
    </div>
  );
}