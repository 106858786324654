import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function ProductFilter({
  products,
  setSelectedProduct,
  selectedProduct,
  setPopulateProducts,
}: {
  products: any;
  setSelectedProduct: (data: any) => void;
  selectedProduct: any;
  setPopulateProducts: (data: any) => void;
}) {
  
  return (
    <>
    {products && (
    <Autocomplete
      sx={{ width: "250px" }}
      id="size-small-outlined"
      size="small"
      multiple
      value={selectedProduct}
      onChange={(event, value) => setSelectedProduct(value)}
      onInputChange={(event, value) => {
        if (value && products.some((option: any) => option.title === value)) {
          setSelectedProduct(value);
        }
        setPopulateProducts(value);
      }}
      options={products.filter(
        (product: any) => !selectedProduct.find((sp: any) => sp.id === product.id)
      )}
      getOptionLabel={(option:any) => `${option?.client_id} - ${(option?.title.length > 20 ? option?.title.substr(0, 20) + '...' : option?.title)}`}
      filterOptions={(options) => options}
      renderInput={(params) => {
        const { InputProps, ...restParams } = params;
        const { startAdornment, ...restInputProps } = InputProps;
      
        return (
            <TextField
                {...restParams}
                InputProps={{
                ...restInputProps,
                startAdornment: (
                    <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                            {startAdornment}
                    </div>
                ),
                }}
                label="Select Product" 
                placeholder="Product"
            />
        )
    }}
    />
   )}
   </>
  );
}
