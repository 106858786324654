import React, { useEffect } from 'react'
import Header from './Header'
import Style from './dashboard.module.css'
import SelectEvents from './SelectEvents'
import Events from './Events'
import moment from 'moment';
import Loader from '../../Loader/Loader'
import ProductFilter from '../../Filters/ProductFilter'
import DateFilter from '../../Filters/DateFilter'
import axios from 'axios'

const experiment = [
   "Skinq90",
   "Troocatalog50",
   "cartvisibility50",
   "Totem_33",
   "Bsc_90",
   "blissvive_90"
]

const variants = [
  "82333883483843",
  "72339439488343",
  "34334656343255",
]

const variation = [
  "withTroopod",
  "withoutTroopod",
  "withTroopodcss",
  "withoutTroopodcss"
]



const  Dashboard = ({setStoreClient}:{setStoreClient:(data:any)=>void;}) => {
  const [store, setStore] = React.useState<any>();
  const [storeDetails , setStoreDetails] = React.useState<any>();
  const [EventsList , setEventsList] = React.useState<any>();
  const [productsList, setProductsList] = React.useState<any>();
  const [populateProducts, setPopulateProducts] = React.useState<any>();
  const [selectedEvent , setSelectedEvent] = React.useState<any>();
  const [selectedProduct , setSelectedProduct] = React.useState<any>([]);
  const [selectedVariant , setSelectedVariant] = React.useState<any>();
  const [selectedExperiment , setSelectedExperiment] = React.useState<any>();
  const [selectedVariation , setSelectedVariation] = React.useState<any>();
  const [startDate, setStartDate] = React.useState<moment.Moment>(moment().startOf('day'));
  const [endDate, setEndDate] = React.useState<moment.Moment>(moment().endOf('day'));
  const [eventsData , setEventsData] = React.useState<any>();
  const [totalCount , setTotalCount] = React.useState<any>();
  const [loading , setLoading] = React.useState<boolean>(false);

  const formatDateStart = (date: moment.Moment): any => {
    console.log(date.format('YYYY-MM-DD HH:mm:ss'))
     return date.utc().format('YYYY-MM-DD HH:mm:ss');
  };

  const formatDateEnd = (date: moment.Moment): any => {
    console.log(date.format('YYYY-MM-DD HH:mm:ss'))
     return date.utc().format('YYYY-MM-DD HH:mm:ss');
  };

  

  useEffect(() => {
    const fetchStores = async () => {
      const hostName = process.env.REACT_APP_ENV === "production" ? "api.prod" : "api.staging";
      try {
        let accessToken:any = localStorage.getItem("accesstoken");
        let refreshToken:any = localStorage.getItem("refreshtoken");
  
        if (!accessToken) {
          console.error("Access token not found");
          return;
        }
  
        accessToken = accessToken;
        refreshToken = refreshToken;
  
        const isTokenExpired = (accessToken:any) => {
          if (!accessToken) {
            return true; 
          }
        
          const tokenData = JSON.parse(atob(accessToken.split('.')[1])); 
          const expirationTime = tokenData.exp * 1000; 
          const currentTime = Date.now(); 
        
          return currentTime >= expirationTime;
        };
        

        const isTokenExpire:any = isTokenExpired(accessToken)  
        if (isTokenExpire && refreshToken) {

          const refreshResponse = await axios.post(
            `https://${hostName}.troopod.io/api/token/refresh/`,
            { refresh_token: refreshToken }
          );

          localStorage.setItem("accesstoken",refreshResponse.data.access);


          const storeResponse = await axios.get(`https://${hostName}.troopod.io/store/`, {
            headers: {
              Authorization: `Bearer ${refreshResponse.data.access}`,
            },
          });
  
          setStore(storeResponse.data[0]);
          setStoreDetails(storeResponse.data);
          return storeResponse.data[0];
        } else {
          
          const response = await axios.get(`https://${hostName}.troopod.io/store/`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
  
          setStore(response.data[0]);
          setStoreClient(response.data[0])
          setStoreDetails(response.data);
          return response.data[0];
        }
      } catch (err: any) {
        console.error("Failed to fetch stores:", err);
      }
    };
  
    fetchStores();
  }, []);
  
  
 useEffect(() => {
  const hostName = process.env.REACT_APP_ENV === "production" ? "api.prod" : "api.staging";
  fetch(`https://${hostName}.troopod.io/analytics/api/events` , {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    },
  })
    .then(response => response.json())
    .then((data) => {
        setEventsList(data);
    })
    .catch(error => console.error('Error fetching data:', error));

}, []);


useEffect(() => {
  const hostName = process.env.REACT_APP_ENV === "production" ? "api.prod" : "api.staging";
  fetch(
    `https://${hostName}.troopod.io/product/store/${store?.id}?query=${
      populateProducts ? populateProducts : ""
    }` , {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      setProductsList([{
        "id": 3318,
        "client_id": "Select All Products",
        "title": "",
        "product_status": "active",
        "product_live_status": "ProductLiveStatus.INACTIVE",
        "created_at": "2024-04-05T08:57:48.203423Z",
        "updated_at": "2024-07-18T16:49:28.124670Z"
    }, ...data]);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
}, [populateProducts, store]);



const handleEventchange = (datatype: string, value: any) => {
  switch (datatype) {
    case 'Eventslist':
      setSelectedEvent(value)
      break; 
    case 'Variants':
      setSelectedVariant(value)
      break;
    case 'Experiment':
      setSelectedExperiment(value)
      break;
    case 'Variation':
      setSelectedVariation(value)
      break;
    default:
      console.log("Unknown datatype");
      break;
  }

}

useEffect(()=>{
  const hostName = process.env.REACT_APP_ENV === "production" ? "api.prod" : "api.staging";
  const filter:any = {};
  let preset:any = '';
  let productIds:any = [];
  if (selectedEvent) {
    filter['title'] = selectedEvent;
  }
  else{
    filter['title'] = "TP_CART/BUY_NOW_BUTTON/CLICK";
  }

  if (selectedProduct && selectedProduct.some((product: any) => product.client_id !== 'Select All Products')) {
    productIds = selectedProduct.map((product: any) => `"${product.client_id}"`).join(', ');
  } else if(selectedProduct.some((product: any) => product.client_id == 'Select All Products')){
    preset = '&preset=all';
  }
  
    
  
  if (selectedVariant) {
    filter['payload.product.variant'] = parseInt(selectedVariant);
  }
  if(selectedVariation && selectedExperiment)
  {
    filter[`payload.experiments.${selectedExperiment}.name`] = selectedVariation;
  }

  const url = `filter?filter=${JSON.stringify(filter)}&product_ids=[${productIds}]${preset}&store_id=${store?.id}&start_date=${formatDateStart(startDate)}&end_date=${formatDateEnd(endDate)}`
  setLoading(true)
  window.history.pushState({}, "", "/dashboard/" + url);
  fetch(`https://${hostName}.troopod.io/analytics/api/${url}` , {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    },
  })
  .then(response => response.json())
  .then((data) => {
      setEventsData(data.data)
      setTotalCount(data.total_count)
      setLoading(false)
     }
    )
  .catch(error => console.error('Error fetching data:'));


},[selectedEvent , selectedExperiment , selectedProduct , selectedVariant , selectedVariation , startDate , endDate , store ])

console.log(productsList)

  return (
    <div>
      {loading && <Loader />}
      <Header setStoreClient={setStoreClient} store={store} setStore={setStore} storeDetails={storeDetails}/>
      <div className={Style.dashboardcontainer}>
      <div style={{width:'250px' , marginLeft:'24px'}}>
      <DateFilter
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
      </div>
      <div className={Style.filtersection}>
        <div className={Style.filterbar}>
          <SelectEvents handleEventchange={(value) => handleEventchange('Eventslist', value)} data={EventsList} label={'Select Event'}/>
        </div>
        <div className={Style.productfilter}>
          <ProductFilter
            setSelectedProduct={setSelectedProduct}
            selectedProduct={selectedProduct}
            products={productsList}
            setPopulateProducts={setPopulateProducts}
          />
        </div>
        <div className={Style.variantfilter}>
          <SelectEvents handleEventchange={(value) => handleEventchange('Variants', value)} data={variants} label={'Select VariantId'}/>
        </div>
        <div className={Style.experimentfilter}>
          <SelectEvents handleEventchange={(value) => handleEventchange('Experiment', value)} data={experiment} label={'Select Experiment'}/>
        </div>
        <div className={Style.experimentvariation}>
          <SelectEvents handleEventchange={(value) => handleEventchange('Variation', value)} data={variation} label={'Select Variation'}/>
        </div>
        
      </div>
      <div className={Style.events_section}>
      <span>showing {eventsData?.length} most recent results of {totalCount} matches</span>
         <Events eventsData={eventsData}/>
      </div>
      </div>
    </div>
  )
}

export default Dashboard