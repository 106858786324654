import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import { Box, Typography } from "@mui/material";
import SignalCellularAltRoundedIcon from '@mui/icons-material/SignalCellularAltRounded';

function SimpleCard({ color, title, count, percentage }:{color:any;title:any;count:any;percentage:any;}) {
  return (
    <Card  sx={{overflow:'visible' , width:'280px',height:'120px'}}>
      <Box display="flex" justifyContent="space-between" pt={1} px={2}>
      <Box sx={{ 
             variant: "gradient", 
             background:"linear-gradient(to top right,#212121 , #424242 )",
             coloredShadow: color,
             borderRadius: "0.75rem",
             color:"white",
             display: "flex",
             justifyContent: "center",
             alignItems: "center",
             width: "3rem",
             height: "3rem",
             boxShadow:"rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(0, 0, 0, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem"
            }}
            >
          <SignalCellularAltRoundedIcon fontSize="medium" color="inherit" />
        </Box>
        <Box textAlign="right" lineHeight={1.25} sx={{paddingLeft:'15px', paddingRight:"5px" , marginLeft:"15px"}} >
        <Typography variant="button" fontWeight="light" color="text" sx={{ fontSize: '13.5px', textTransform: 'none'}}>
          {title}
       </Typography>
          <Typography variant="h6" >{count}</Typography>
        </Box>
      </Box>
      <Divider sx={{marginTop:'15px'}} />
      <Box pb={2} px={2}>
        <Typography component="p" variant="button" sx={{ textTransform: 'none', float:'right'}} color="text" display="flex">
          {percentage.label}&nbsp;
          <Typography
            component="span"
            variant="button"
            fontWeight="bold"
            color={'#8b0000'}
          >
            {percentage.amount}
          </Typography>
        </Typography>
      </Box>
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCard
SimpleCard.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the ComplexStatisticsCard
SimpleCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
};

export default SimpleCard;