import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


export default function SelectEvents({data, label , handleEventchange}:{data:any,label:string , handleEventchange:(value:any)=>void}) {
   const [inputValue , setInputValue] = React.useState();
   const handleFilterData = (event:any , value:any) =>{
      setInputValue(value)
      handleEventchange(value)
   }
  
  return (
    <Autocomplete style={{width:'250px'}}
  value={inputValue}
  size="small"
  onChange={handleFilterData}
  filterOptions={(options, params) => {
    const filtered = options.filter(option =>
      option?.toLowerCase().startsWith(params.inputValue.toLowerCase())
    );

    if (params.inputValue !== '' && filtered.indexOf(params.inputValue) === -1) {
      filtered.push(params.inputValue);
    }

    return filtered;
  }}
  selectOnFocus
  clearOnBlur
  handleHomeEndKeys
  id="filter-data"
  options={data} 
  getOptionLabel={(option:any) => option}
  renderOption={(props, option) => <li {...props}>{option}</li>}
  sx={{ width: 300 }}
  freeSolo
  renderInput={(params) => (
    <TextField {...params} label={label} />
  )}
/>

  );
}
