import './App.css';
import { BrowserRouter, Routes , Route, Navigate } from 'react-router-dom';
import Autentication from './components/Authentication/Authentication';
import Dashboard from './components/Dashboard/InternalBoard/Dashboard';
import { jwtDecode } from 'jwt-decode';
import Boards from './components/Dashboard/Boards/Boards';
import React from 'react';

const PrivateRoute = ({children }: { children: any }) => {
  const token:any = localStorage.getItem("accesstoken");
  const accesstoken:any = token
  let isAuthenticated = false;
  if (accesstoken) {
  const decodedToken = jwtDecode<any>(accesstoken);
  isAuthenticated = decodedToken.exp > Date.now() / 1000;
  }

  return isAuthenticated ? children : <Navigate to="/" />;
};

function App() {
   const [ store , setStoreClient] = React.useState(null);
  return (
    <BrowserRouter>
    <div>
      <Routes>
        <Route path='' element={<Autentication />} />
        <Route path="/dashboard/*" element={<PrivateRoute><Dashboard setStoreClient={setStoreClient} /></PrivateRoute>} />
        <Route path='/boards' element={<PrivateRoute><Boards store={store} setStoreClient={setStoreClient} /></PrivateRoute>} />
      </Routes>
    </div>
    </BrowserRouter>
  );
}

export default App;
