import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Loader from '../Loader/Loader';


function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://troopod.io/">
        Troopod.io
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Autentication = () => {
const [loading , setLoading] = React.useState<boolean>(false)
const [response , setResponse] = React.useState<any>();
const navigate = useNavigate();

  const handleSubmit = async (event:any) => {
    const hostName = process.env.REACT_APP_ENV === "production" ? "api.prod" : "api.staging";
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if(data.get('username') && data.get('password')){
      setLoading(true)
    try {
      const response = await axios.post(`https://${hostName}.troopod.io/api/token/`, {
        username: data.get('username'),
        password: data.get('password')
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setResponse(response)
      const storeresponse = await axios.get(`https://${hostName}.troopod.io/store/`, {
            headers: {
              Authorization: `Bearer ${response.data.access}`,
            },
          });
      setLoading(false)
      if(response.status === 200)
      {
        localStorage.setItem("accesstoken", response.data.access);
        localStorage.setItem("refreshtoken", response.data.refresh);
        if (response.data.access && storeresponse.data.length > 2) {
           navigate("/dashboard/*")
        }
        else if(response.data.access && storeresponse?.data?.length <= 2){
          navigate("/boards")
        }
        else if(storeresponse && storeresponse?.data?.length < 1){
            alert("No store Found on this user")
        }
      }
      else{

      }
            
    } catch (error:any) {
      setLoading(false)
      setResponse(error.response)
      console.error('Error:', error);
    }
  }
  }

  return (
    <>
     {loading && <Loader/> }
      {response && 
       <Alert severity={response?.status === 200 ? "success" : "error"}>{response?.status === 200 ? "Login Success" : "Login Failed"}</Alert>  
      }
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="username"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
      </>    
  );
}




export default Autentication;
