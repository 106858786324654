import * as React from 'react';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


export default function Events({eventsData}:{eventsData:any}) {
  
  
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell >Event Name</TableCell>
            <TableCell align="center">Time</TableCell>
            <TableCell align="center">Distinct Id</TableCell>
            <TableCell align="center">City</TableCell>
            <TableCell align="center">Country</TableCell>
            <TableCell align="center">Operating System</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {eventsData?.map((event:any , index:number) => (
            <EventRow key={index} row={event} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


function EventRow(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  

//   function timeSince(date_str:any) {
    
//     var date:any = new Date(date_str);
//     var seconds:any = Math.floor((new Date().getTime() - date) / 1000);
//     var interval = Math.floor(seconds / 31536000);

//     if (interval > 1) {
//         return interval + " years";
//     }
//     interval = Math.floor(seconds / 2592000);
//     if (interval > 1) {
//         return interval + " months";
//     }
//     interval = Math.floor(seconds / 86400);
//     if (interval > 1) {
//         return interval + " days";
//     }
//     interval = Math.floor(seconds / 3600);
//     if (interval > 1) {
//         return interval + " hours";
//     }
//     interval = Math.floor(seconds / 60);
//     if (interval > 1) {
//         return interval + " minutes";
//     }
//     return Math.floor(seconds) + " seconds";
// }


  function formatDate(inputDate:any) {
    const date = new Date(inputDate);
    const day = ("0" + date.getDate()).slice(-2); 
    const month = ("0" + (date.getMonth() + 1)).slice(-2); 
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.title ? row.title : 'null'}
        </TableCell>
        <TableCell align="center">{row.created_at ? formatDate(row.created_at.$date) : 'null'}</TableCell>
        <TableCell align="center">{row.payload.User?.id ? row.payload.User?.id : 'null'}</TableCell>
        <TableCell align="center">Hyderabad</TableCell>
        <TableCell align="center">India</TableCell>
        <TableCell align="center">{row.payload.Browser?.platform ? row.payload.Browser.platform : 'null' }</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
           <RenderData data={row} />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const renderValue = (value: any) => {
  if (typeof value === 'object' && value !== null) {
    if ('$oid' in value) {
      return value['$oid'];
    } else if ('$date' in value) {
      const date = new Date(value['$date']);
      return date.toISOString(); 
    } else {
      return JSON.stringify(value);
    }
  }
  return value; 
};

const RenderData = ({ data }: { data: any }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {Object.keys(data).map((key) => (
            key !== 'payload' ? (
              <TableRow style={{display:'flex', border:'1px solid #E0E0E0'}} key={key}>
                <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                  {key}
                </TableCell>
                <TableCell>{renderValue(data[key])}</TableCell>
              </TableRow>
            ) : (
              <RenderData key={key} data={data.payload} />
            )
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};