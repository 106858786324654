import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function ExperimentFilter({
  experiments,
  setSelectedExperiment,
  selectedExperiment
}: {
  experiments: any;
  setSelectedExperiment:(data:any)=>void;
  selectedExperiment:any;
}) {
    
  return (
    <>
    {experiments && (
    <Autocomplete sx={{width:'250px'}}
    id="size-small-outlined"
    size="small"
    value={selectedExperiment}
    onChange={(event, value) => setSelectedExperiment(value)} 
    options={experiments}
    getOptionLabel={(option:any) => option?.name}
    renderInput={(params) => (
      <TextField {...params} label="Select Experiment" placeholder="experiment" />
    )}
  />
   )}
   </>
  );
}
