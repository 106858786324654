import React from 'react'
import { Box, Card, Typography } from '@mui/material'
import Style from './card.module.css'
import StraightIcon from '@mui/icons-material/Straight';

function GraphCard({title,color, count}:{title:string,color:string; count:any;}) {

  return (
    <div>
        <Card sx={{
           overflow: 'visible', 
           width: '300px',
           height: '170px', 
           position: 'relative', 
           background: color === "blue" ? "linear-gradient(195deg, rgb(73, 163, 241), rgb(63, 138, 237))" : color === "red" ? "#f75a59" : "#FFB534",
        }}> 
        <Box lineHeight={1.25} sx={{paddingLeft:'10px', paddingRight:"5px" }} >
        <Typography variant="button" fontWeight="light" color="white" sx={{ fontSize: '20px', textTransform: 'none'}}>
          {title}
       </Typography>
         <Box display={'flex'} alignItems={'center'} >
         <Typography variant="h4" color={parseInt(count) < 0 ? 'red' : 'green'}>{count}</Typography><StraightIcon fontSize='large' style={{marginTop: "-2px",transform: parseInt(count) < 0 ? "rotate(180deg)" : "", color: parseInt(count) < 0 ? 'red' : 'green'}}/>
         </Box>
        </Box>
        <Bars color={color}/>
        </Card>
    </div>
  )
}

export default GraphCard


const Bars = ({color}:{color:string}) =>{
    return(
       <div className={Style.graph_container}>
        <span className={Style.bar} style={{height: "20px", background: color === "blue" ? "rgba(32, 89, 164, 0.734)" : color === "red" ? "#FC3433" : "#FFA509"}}></span>
        <span className={Style.bar} style={{height: "30px", background: color === "blue" ? "rgba(32, 89, 164, 0.734)" : color === "red" ? "#FC3433" : "#FFA509"}}></span>
        <span className={Style.bar} style={{height: "75px", background: color === "blue" ? "rgba(32, 89, 164, 0.734)" : color === "red" ? "#FC3433" : "#FFA509"}}></span>
        <span className={Style.bar} style={{height: "40px", background: color === "blue" ? "rgba(32, 89, 164, 0.734)" : color === "red" ? "#FC3433" : "#FFA509"}}></span>
        <span className={Style.bar} style={{height: "35px", background: color === "blue" ? "rgba(32, 89, 164, 0.734)" : color === "red" ? "#FC3433" : "#FFA509"}}></span>
        <span className={Style.bar} style={{height: "50px", background: color === "blue" ? "rgba(32, 89, 164, 0.734)" : color === "red" ? "#FC3433" : "#FFA509"}}></span>
        <span className={Style.bar} style={{height: "70px", background: color === "blue" ? "rgba(32, 89, 164, 0.734)" : color === "red" ? "#FC3433" : "#FFA509"}}></span>
        <span className={Style.bar} style={{height: "45px", background: color === "blue" ? "rgba(32, 89, 164, 0.734)" : color === "red" ? "#FC3433" : "#FFA509"}}></span>
        <span className={Style.bar} style={{height: "30px", background: color === "blue" ? "rgba(32, 89, 164, 0.734)" : color === "red" ? "#FC3433" : "#FFA509"}}></span>
        <span className={Style.bar} style={{height: "40px", background: color === "blue" ? "rgba(32, 89, 164, 0.734)" : color === "red" ? "#FC3433" : "#FFA509"}}></span>
        <span className={Style.bar} style={{height: "30px", background: color === "blue" ? "rgba(32, 89, 164, 0.734)" : color === "red" ? "#FC3433" : "#FFA509"}}></span>
        <span className={Style.bar} style={{height: "60px", background: color === "blue" ? "rgba(32, 89, 164, 0.734)" : color === "red" ? "#FC3433" : "#FFA509"}}></span>
        <span className={Style.bar} style={{height: "35px", background: color === "blue" ? "rgba(32, 89, 164, 0.734)" : color === "red" ? "#FC3433" : "#FFA509"}}></span>
       </div>
    )
}


